const sanitizeNumber = (number: string) => {
    return number.replace(/\(|\)|-|\s|\+| /g, '')
}

export const setPhoneCountryCode = (number: string) => {
    const sanitized_number = sanitizeNumber(number)
    if (/^01.{0,13}$/.test(sanitized_number))
        return sanitized_number.replace(/^01/gi, '601')
    if (/^1.{0,15}$/.test(sanitized_number))
        return sanitized_number.replace(/^1/gi, '601')
    if (/^03.{0,13}$/.test(sanitized_number))
        return sanitized_number.replace(/^03/gi, '603')
    if (/^04.{0,13}$/.test(sanitized_number))
        return sanitized_number.replace(/^04/gi, '604')
    if (/^05.{0,13}$/.test(sanitized_number))
        return sanitized_number.replace(/^05/gi, '605')
    if (/^06.{0,13}$/.test(sanitized_number))
        return sanitized_number.replace(/^06/gi, '606')
    if (/^07.{0,13}$/.test(sanitized_number))
        return sanitized_number.replace(/^07/gi, '607')
    if (/^8.{0,15}$/.test(sanitized_number))
        return sanitized_number.replace(/^8/gi, '608')
    if (/^08.{0,13}$/.test(sanitized_number))
        return sanitized_number.replace(/^08/gi, '608')
    if (/^09.{0,13}$/.test(sanitized_number))
        return sanitized_number.replace(/^09/gi, '609')

    return sanitized_number
}

export const isValidPhoneNumber = (value: string) =>
    /^[0-9+]?[0-9- ]{0,30}$/.test(value)
