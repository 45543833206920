import * as React from 'react'

const { lazy } = React
const Login = lazy(() => import('Components/Pages/Login'))
const Main = lazy(() => import('Components/Pages/Main'))
const Telemarketing = lazy(() => import('Components/Pages/Telemarketing'))
const Settings = lazy(() => import('Components/Pages/Settings'))
const AccessDenied = lazy(() => import('Components/Pages/AccessDenied'))

export const ROUTES_CONFIG = [
    {
        key: 'login',
        path: ['/login', '/'],
        title: 'Please log in',
        component: Login,
        is_exact: true,
    },
    {
        key: 'main',
        path: '/main',
        title: 'Main',
        is_authenticated: true,
        is_exact: true,
        component: Main,
    },
    {
        key: 'telemarketing',
        path: '/telemarketing',
        title: 'Telemarketing',
        is_authenticated: true,
        is_exact: true,
        is_navbar_link: true,
        roles: ['rptm', 'admin'],
        component: Telemarketing,
    },
    {
        key: 'settings',
        path: '/settings',
        title: 'Settings',
        is_authenticated: true,
        is_exact: true,
        is_navbar_link: true,
        roles: ['admin'],
        component: Settings,
    },
    {
        key: 'unauthorized',
        path: '/unauthorized',
        title: 'Unauthorized Access',
        is_authenticated: true,
        is_exact: true,
        component: AccessDenied,
    },
]
