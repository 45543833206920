import deepmerge from 'deepmerge'
import { ObjectWithAnyKey } from 'Definitions'

export const filterObjectByPredicate = (
    obj: ObjectWithAnyKey,
    predicate: (a: string[], b: number) => void
) => Object.fromEntries(Object.entries(obj).filter(predicate))

export const isEmptyObject = (object: ObjectWithAnyKey) =>
    object &&
    Object.keys(object).length === 0 &&
    Object.getPrototypeOf(object) === Object.prototype

export const getObjectDiff = (
    first_object: ObjectWithAnyKey,
    second_object: ObjectWithAnyKey
) =>
    Object.fromEntries(
        Object.entries(second_object).filter(
            ([key, val]) => key in first_object && first_object[key] !== val
        )
    )

export const deepMergeObjects = (
    first_object: ObjectWithAnyKey,
    second_object: ObjectWithAnyKey
) => deepmerge(first_object, second_object)
