export const getIssuerBranchGeoCode = async (postcode: number) => {
    const ENDPOINT_URI =
        process.env.REACT_APP_AMPLIFY_API_JIRNEXU ||
        'https://platform.ringgitplus.com/api'
    const geocode = await fetch(
        `${ENDPOINT_URI}/location/postcode/details?countryCode=MY&postcode=${postcode}`
    )
    const { ok }: Response = geocode
    return ok
        ? geocode.json()
        : { error: 'An error occurred making the request' }
}

export const getIssuerBranchList = async (
    source: string,
    latitude: number,
    longitude: number
) => {
    const ENDPOINT_URI =
        process.env.REACT_APP_AMPLIFY_API_JIRNEXU ||
        'https://platform.ringgitplus.com/api'
    const branch_list = await fetch(
        `${ENDPOINT_URI}/location/search?countryCode=MY&source=${source}&geocode.latitude=${latitude}&geocode.longitude=${longitude}`
    )
    const { ok }: Response = branch_list
    return ok
        ? branch_list.json()
        : { error: 'An error occurred making the request' }
}
