import * as React from 'react'
import { Layout, Menu } from 'antd'
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'
import { useThemeSwitcher } from 'react-css-theme-switcher'
import { AuthContext } from 'Store'
import { ROUTES_CONFIG as pages } from 'Config'
import { getArrayMatch, getUserRoles } from 'Utils'
import { RouteConfigTypes } from 'Definitions'
import { ReactComponent as RPLogoLight } from 'Assets/common/ringgitplus.svg'
import { ReactComponent as RPLogoDark } from 'Assets/common/ringgitplus-dark.svg'

const HeaderComponent: React.FC<RouteComponentProps> = ({ location }) => {
    const { Header } = Layout
    const { useContext, useEffect, useState } = React
    const [access_roles, setAccessRoles] = useState<string[]>([])
    const authCtx = useContext(AuthContext)
    const { user_info } = authCtx
    const { pathname } = location
    const { currentTheme } = useThemeSwitcher()

    useEffect(() => {
        if (user_info) {
            const groups = user_info['cognito:groups']
            const roles = getUserRoles(groups)
            setAccessRoles(roles)
        }
    }, [user_info])

    return (
        <Header
            style={{
                position: 'fixed',
                zIndex: 1,
                width: '100%',
                backgroundColor: currentTheme === 'light' ? '#fff' : '#303030',
                borderBottom: `1px solid ${
                    currentTheme === 'light' ? '#f0f0f0' : '#424242'
                }`,
            }}
        >
            {currentTheme === 'light' ? (
                <RPLogoLight
                    className="rp-logo"
                    style={{
                        float: 'left',
                        width: '120px',
                        height: '31px',
                        margin: '1.2rem 1.6rem 1rem 0px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                />
            ) : (
                <RPLogoDark
                    className="rp-logo"
                    style={{
                        float: 'left',
                        width: '120px',
                        height: '31px',
                        margin: '1.2rem 1.6rem 1rem 0px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                />
            )}
            {user_info && (
                <>
                    <Menu
                        mode="horizontal"
                        defaultSelectedKeys={['/']}
                        selectedKeys={[pathname]}
                    >
                        {pages
                            .filter((page) => {
                                const { is_navbar_link, roles } =
                                    page as RouteConfigTypes
                                return (
                                    is_navbar_link &&
                                    getArrayMatch(roles, access_roles)
                                )
                            })
                            .map((page) => {
                                const { path, title } = page
                                const link_pathname = Array.isArray(path)
                                    ? path[0]
                                    : path
                                return (
                                    <Menu.Item key={link_pathname}>
                                        <Link to={link_pathname}>{title}</Link>
                                    </Menu.Item>
                                )
                            })}
                    </Menu>
                </>
            )}
        </Header>
    )
}

export default withRouter(HeaderComponent)
