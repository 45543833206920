export const signupErrorChecker = () => {
    // Workaround for the cognito issue when the user is linking social provider accounts for the first time
    // Refer to: https://stackoverflow.com/questions/47815161/cognito-auth-flow-fails-with-already-found-an-entry-for-username-facebook-10155
    const { href } = window.location
    if (href) {
        if (/Already.found.an.entry/gi.test(href)) {
            sessionStorage.setItem('signup_error', 'yes')
        }
    }
}

export const signupErrorCleanUp = () => {
    sessionStorage.removeItem('signup_error')
}
