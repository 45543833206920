import * as React from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import { AuthContext } from 'Store'
import { getArrayMatch, getUserGroups, getUserRoles } from 'Utils'

const PrivateRoute: React.FC<IPrivateRouteProps> = ({
    component,
    location,
    exact,
    path,
    redirect_path,
    roles,
}) => {
    const { useContext } = React
    const authCtx = useContext(AuthContext)
    const { user_info, user } = authCtx
    if (user) {
        const cognito_user_groups = getUserGroups(user_info)
        const user_roles = getUserRoles(cognito_user_groups)
        const has_access = Array.isArray(roles)
            ? getArrayMatch(roles, user_roles)
            : true
        return has_access ? (
            <Route
                exact={exact}
                path={path}
                component={component}
                render={undefined}
            />
        ) : (
            <Redirect
                to={{
                    pathname: redirect_path || '/unauthorized',
                    state: { from: location },
                }}
            />
        )
    }
    return <Redirect to={{ pathname: '/login', state: { from: location } }} />
}

interface IPrivateRouteProps extends RouteProps {
    redirect_path?: string
    roles: string[] | undefined
}

export default PrivateRoute
