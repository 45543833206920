import dayjs, { Dayjs } from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedFormat from 'dayjs/plugin/localizedFormat'

export const parseDayjsDate = (date?: string | Dayjs, format?: string) => {
    dayjs.extend(localizedFormat)
    return dayjs(date).format(format || 'YYYY-MM-DD')
}

export const getTimeElapsed = (timestamp: number) => {
    dayjs.extend(relativeTime)
    return dayjs.unix(timestamp).fromNow()
}

export const getAgeFromDate = (date: string) => {
    dayjs.extend(relativeTime)
    return parseInt(dayjs(date).fromNow(true), 10)
}

export const getDateDiff = (
    first_date: string | Dayjs,
    second_date: string | Dayjs
) => {
    const parsed_first_date =
        typeof first_date === 'string' ? dayjs(first_date) : first_date
    const parsed_second_date =
        typeof second_date === 'string' ? dayjs(second_date) : second_date
    return parsed_first_date.diff(parsed_second_date, 'year', true)
}
