import { fetchAPI } from 'Utils'

export const getProductList = async (
    token: string,
    product_type: string,
    age: number,
    income: number,
    loan_amount?: number,
    loan_tenure?: number,
    loan_purpose?: string
) => {
    if (!token) return []
    let request_string = `/campaigns/products?product_type=${product_type}&age=${age}&income=${income}`
    if (product_type === 'personal-loan') {
        request_string += `&loan_amount=${loan_amount}&loan_tenure=${loan_tenure}&loan_purpose=${loan_purpose}`
    }
    const products_list = await fetchAPI(request_string, null, token, 'GET')
    return products_list
}
