import * as React from 'react'
import { Amplify, Auth, Hub } from 'aws-amplify'
import { CognitoUser, CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'
import { AuthContext } from 'Store'
import { AMPLIFY_CONFIG as config } from 'Config'
import { ObjectWithAnyKey } from 'Definitions'
import { signupErrorCleanUp } from 'Utils'

Amplify.configure(config)

export const useAuth = () => {
    const { useEffect, useRef, useState } = React
    const authCtxRef = useRef(false)
    const [user, setUser] = useState<CognitoUser | null>(null)
    const [token, setToken] = useState<string>('')
    const [user_info, setUserInfo] = useState<ObjectWithAnyKey | null>(null)
    const [theme, setTheme] = useState<string>(
        localStorage.getItem('sato-theme') || 'light'
    )
    const [is_ui_blocked, setUIBlocked] = useState<boolean>(false)
    const [block_ui_message, setBlockerUIMessage] = useState<string>('')
    const signup_error = sessionStorage.getItem('signup_error')

    const getUser = () =>
        Auth.currentAuthenticatedUser()
            .then(async (userData) => {
                // TODO: Use refresh token to refresh cognito access token
                // const currentSession = userData.signInUserSession
                // console.log(currentSession)
                // userData.refreshSession(
                //     currentSession.refreshToken,
                //     (err: string, session: CognitoUser) => {
                //         console.log(err, session)
                //         // do something with the new session
                //     }
                // )
                const session = (await Auth.currentSession()).getIdToken()
                const { payload } = session
                const id_token = session.getJwtToken()
                setUserInfo(payload)
                setToken(id_token)
                return userData
            })
            .catch((error) => {
                // Handle signIn errors
                // eslint-disable-next-line no-console
                console.warn('Not signed in', error)
            })

    useEffect(() => {
        if (!authCtxRef.current) {
            Hub.listen('auth', ({ payload: { event, data } }) => {
                switch (event) {
                    case 'signIn':
                    case 'cognitoHostedUI':
                        getUser().then((userData) => setUser(userData))
                        break
                    // case 'customOAuthState':
                    //     setCustomState(data)
                    //     break
                    case 'signOut':
                        setUser(null)
                        break
                    case 'parsingCallbackUrl':
                        // eslint-disable-next-line no-console
                        console.warn('parsing callback URL:', data)
                        break
                    case 'signIn_failure':
                    case 'cognitoHostedUI_failure':
                        // eslint-disable-next-line no-console
                        console.warn('Sign in failure', data)
                        break
                    default:
                }
            })

            getUser().then((userData) => {
                setUser(userData)
                authCtxRef.current = true
            })
        }
    }, [])

    const signIn = () => {
        Auth.federatedSignIn({
            provider: CognitoHostedUIIdentityProvider.Google,
        })
    }

    const signOut = () => {
        Auth.signOut()
    }

    const toggleTheme = (is_dark_mode: boolean) => {
        const selected_theme = is_dark_mode ? 'dark' : 'light'
        setTheme(selected_theme)
        localStorage.setItem('sato-theme', selected_theme)
    }

    const setBlockUI = (state: boolean) => setUIBlocked(state)

    const setBlockUIMessage = (state: string) => setBlockerUIMessage(state)

    useEffect(() => {
        if (signup_error) {
            signIn()
            signupErrorCleanUp()
        }
    }, [signup_error])

    return {
        user,
        user_info,
        token,
        theme,
        toggleTheme,
        signIn,
        signOut,
        getUser,
        is_ui_blocked,
        setBlockUI,
        block_ui_message,
        setBlockUIMessage,
    }
}

export const useUser = () => {
    const { user } = React.useContext(AuthContext)
    if (!user) return null

    // See https://github.com/aws-amplify/amplify-js/issues/4927
    // @ts-ignore
    return user.attributes
}

export const useSignIn = () => React.useContext(AuthContext).signIn

export const useSignOut = () => React.useContext(AuthContext).signOut

export const useGetUser = () => React.useContext(AuthContext).getUser
