import { Dayjs } from 'dayjs'
import {
    BINARY_DROPDOWNS as binary_dropdowns,
    ADDRESS_FIELDS_CONTEXT_MAP as address_context_map,
} from 'Config'
import {
    IBranchListResponse,
    ObjectWithAnyKey,
    ValidationFunction,
    TPhoneValue,
} from 'Definitions'
import {
    toSnakeCase,
    toCamelCase,
    handleCustomerFieldTypes,
    VALIDATION_RULES as validation_rules,
    removeItemFromArray,
    parseDayjsDate,
    isEmptyArray,
    isEmptyObject,
    parseStringToHTML,
    setPhoneCountryCode,
    removeArrayDuplicates,
} from 'Utils'

const parseAddressFields = (
    address_type: string,
    address: ObjectWithAnyKey
) => {
    if (typeof address !== 'object' || isEmptyObject(address)) return {}
    const address_values = {}
    Object.keys(address).forEach((field: string) => {
        const fieldname = address_type
        const address_group =
            address_context_map[fieldname as keyof typeof address_context_map]
        const form_field_name =
            address_group[field as keyof typeof address_group]
        if (form_field_name) {
            Object.assign(address_values, { [form_field_name]: address[field] })
        }
    })
    return address_values
}

const getContextFieldValue = (
    context_fields: ObjectWithAnyKey,
    field_name: string,
    field_type?: string,
    sub_type?: string
) => {
    const { personalInfo, homeAddress, officeAddress } = context_fields
    const personalInfoValues = personalInfo?.value
    const homeAddressValues =
        homeAddress?.value && !homeAddress?.unconfirmed
            ? parseAddressFields('homeAddress', homeAddress?.value.address)
            : {}
    const officeAddressValues =
        officeAddress?.value && !officeAddress?.unconfirmed
            ? parseAddressFields('officeAddress', officeAddress?.value.address)
            : {}
    const nested_form_values = {
        ...personalInfoValues,
        ...homeAddressValues,
        ...officeAddressValues,
    }
    const nested_keys = Object.keys(nested_form_values)
    if (nested_keys.includes(field_name)) {
        return handleCustomerFieldTypes(
            field_name,
            nested_form_values[field_name],
            field_type,
            true,
            undefined,
            sub_type
        )
    }
    return Object.keys(context_fields).includes(field_name)
        ? handleCustomerFieldTypes(
              field_name,
              context_fields[field_name].value,
              field_type,
              true,
              undefined,
              sub_type
          )
        : undefined
}

export const getCampaignMutableFieldKeys = (fields_data: ObjectWithAnyKey) => {
    const mutable_field_keys: string[] = []
    fields_data.forEach((field: ObjectWithAnyKey) => {
        if (!field.isImmutable) {
            mutable_field_keys.push(field.name)
        }
    })
    return mutable_field_keys
}

export const checkDependencyDefaultValue = (
    default_value: string,
    context_fields: ObjectWithAnyKey
) => {
    if (/{(.*?)}/.test(default_value)) {
        const dependency_fieldname = default_value.substring(
            1,
            default_value.length - 1
        )
        const context_field = context_fields[dependency_fieldname]
        if (context_field) {
            const { value } = context_field
            if (value) {
                return value
            }
        }
    }
    return default_value
}

export const handleDependencyByCondition = (
    enabled_conditional_fields: string[],
    displayRules: ObjectWithAnyKey,
    field_value: string | number | boolean,
    dependency_name: string
) => {
    const { condition, value, type } = displayRules
    const results: string[] = enabled_conditional_fields
    // TODO: Handle more field display deps conditions here
    if (condition === '=') {
        if (type === 'text') {
            if (value === null && !!value === !!field_value) {
                if (!results.includes(dependency_name)) {
                    results.push(dependency_name)
                }
            } else if (typeof value !== null && value === field_value) {
                if (!results.includes(dependency_name)) {
                    results.push(dependency_name)
                }
            } else if (
                // need to handle truthy field_values, e.g - 'true' or 'false' in strings (binary dropdowns)
                ['true', 'false'].includes(value) &&
                typeof field_value === 'boolean' &&
                value === `${field_value}`
            ) {
                if (!results.includes(dependency_name)) {
                    results.push(dependency_name)
                }
            } else {
                removeItemFromArray(results, dependency_name)
            }
        }
    }
    if (condition === '!=') {
        if (type === 'text') {
            if (value !== field_value) {
                if (!results.includes(dependency_name)) {
                    results.push(dependency_name)
                }
            } else {
                removeItemFromArray(results, dependency_name)
            }
        }
    }
    return results
}

export const fieldDependencyHandler = (
    enabled_conditional_fields: string[],
    form_config: ObjectWithAnyKey[],
    field_name: string,
    field_value: string | number | boolean
) => {
    let met_dependencies: string[] = enabled_conditional_fields
    form_config
        .filter((field) => field.displayRules)
        .forEach((field) => {
            const { displayRules, name } = field
            displayRules.forEach((display_rule: ObjectWithAnyKey) => {
                if (field_name === display_rule.name) {
                    const completed_fields: string[] =
                        handleDependencyByCondition(
                            enabled_conditional_fields,
                            display_rule,
                            field_value,
                            name
                        )
                    met_dependencies = completed_fields
                }
            })
        })
    return met_dependencies
}

export const handleSelfRefDependencies = (form_screen: ObjectWithAnyKey) => {
    let self_referencing_list: string[] = []
    const { form_config, form_values } = form_screen

    form_config
        .filter((field: ObjectWithAnyKey) => field?.displayRules)
        .forEach((field: ObjectWithAnyKey) => {
            const { displayRules, name: field_name } = field
            const display_rule = displayRules[0]
            const { name: deps_name } = display_rule
            if (field_name === deps_name) {
                const field_value = form_values[field_name]
                self_referencing_list = [
                    ...self_referencing_list,
                    ...handleDependencyByCondition(
                        self_referencing_list,
                        display_rule,
                        field_value,
                        deps_name
                    ),
                ]
            }
        })

    return removeArrayDuplicates(self_referencing_list)
}

export const getCampaignFieldsConfig = (
    context: ObjectWithAnyKey,
    campaign_forms: ObjectWithAnyKey[]
) => {
    const all_context_fields = Object.fromEntries(Object.entries(context))

    const fields_config = campaign_forms.map((form: ObjectWithAnyKey) => {
        const { title, fields, postUpdateCalls } = form

        const form_values = {}
        fields.map((field: ObjectWithAnyKey) => {
            const { defaultValue, name, type, value, subType } = field

            const getFieldValue = () => {
                if (typeof value === 'boolean') {
                    return value
                }
                if (!defaultValue) {
                    const ctx_field_value = getContextFieldValue(
                        all_context_fields,
                        name,
                        type,
                        subType
                    )
                    return value || ctx_field_value
                }
                return (
                    value ||
                    getContextFieldValue(
                        all_context_fields,
                        name,
                        type,
                        subType
                    ) ||
                    checkDependencyDefaultValue(
                        defaultValue,
                        all_context_fields
                    )
                )
            }

            const field_value = getFieldValue()
            return Object.assign(form_values, { [name]: field_value })
        })
        const custom_immutable_fields = ['monthlyNettIncome']

        const parsed_form_config = fields.map(
            (field_data: ObjectWithAnyKey) => ({
                ...field_data,
                placeholder:
                    field_data?.placeholder ||
                    (field_data?.type === 'dropdown' ||
                    field_data?.type === 'branch'
                        ? `Select ${field_data?.title || 'a value'}`
                        : `Enter ${field_data?.title || 'a value'}`),
                has_display_condition: !!field_data?.displayRules,
                isImmutable:
                    field_data?.isImmutable ||
                    custom_immutable_fields.includes(field_data.name),
            })
        )
        const form_section = {
            title,
            postUpdateCalls,
            key: toSnakeCase(title),
            formId: `${toCamelCase(title)}Form`,
            form_config: parsed_form_config,
            form_values: Object.fromEntries(Object.entries(form_values)),
        }
        return form_section
    })

    return fields_config
}

export const parseCampaignFields = (
    form_config: ObjectWithAnyKey[],
    form_values: ObjectWithAnyKey,
    branch_places?: IBranchListResponse[]
) => {
    const parsed_fields = {}
    Object.keys(form_values).forEach((field_name: string) => {
        form_config.forEach((field_config: ObjectWithAnyKey) => {
            const { name, type, subType } = field_config
            if (name === field_name && type === 'date') {
                Object.assign(parsed_fields, {
                    [field_name]: parseDayjsDate(form_values[field_name]),
                })
            }
            if (
                name === field_name &&
                type === 'branch' &&
                Array.isArray(branch_places)
            ) {
                Object.assign(parsed_fields, {
                    [field_name]: !isEmptyArray(branch_places)
                        ? branch_places.find(
                              (place: IBranchListResponse) =>
                                  place.placeId === form_values[field_name]
                          )
                        : form_values[field_name],
                })
            }
            if (
                name === field_name &&
                subType === 'phone' &&
                form_values[field_name]
            ) {
                let phone_number = form_values[field_name]
                if (typeof form_values[field_name] === 'object') {
                    const { msisdn } = form_values[field_name] as TPhoneValue
                    phone_number = msisdn
                }
                Object.assign(parsed_fields, {
                    [field_name]: setPhoneCountryCode(phone_number),
                })
            }
            if (
                name === field_name &&
                binary_dropdowns.includes(field_name) &&
                form_values[field_name]
            ) {
                Object.assign(parsed_fields, {
                    [field_name]: form_values[field_name] === 'true',
                })
            }
        })
    })
    return { ...form_values, ...parsed_fields }
}

export const handleFieldPeerDependencies = (
    form_config: ObjectWithAnyKey[],
    diff_values: ObjectWithAnyKey,
    values: ObjectWithAnyKey
) => {
    form_config.forEach((field: ObjectWithAnyKey) => {
        const { name, peerDependencies } = field
        if (Object.keys(diff_values).includes(name)) {
            if (peerDependencies) {
                peerDependencies.forEach((field_name: string) => {
                    Object.assign(diff_values, {
                        [field_name]: values[field_name],
                    })
                })
            }
        }
    })
}

export const validationDependencyHandler = (
    type: string,
    condition: string,
    dependency_value: string | number,
    error_msg: string,
    name: string
) => {
    if ((!dependency_value && name !== 'currentDate') || !condition) {
        return true
    }
    return {
        validator(_: unknown, value: string | number | Dayjs) {
            let has_fulfilled_condition = true
            switch (type) {
                // TODO: Handle more validation deps conditions here
                case 'date':
                    if (condition === '>=') {
                        const parsed_value =
                            typeof value === 'object'
                                ? parseDayjsDate(value)
                                : value.toString()
                        const parsed_dependency_value =
                            typeof dependency_value === 'object'
                                ? parseDayjsDate(dependency_value)
                                : dependency_value.toString()
                        if (
                            typeof parsed_dependency_value === 'string' &&
                            typeof parsed_value === 'string'
                        ) {
                            has_fulfilled_condition = value
                                ? validation_rules.dateDiffRule(
                                      parsed_value,
                                      parsed_dependency_value
                                  )
                                : true
                        }
                    } else if (condition === '<=') {
                        const parsed_value =
                            typeof value === 'object'
                                ? parseDayjsDate(value)
                                : value.toString()
                        const getDependencyValue = () => {
                            if (name === 'currentDate') {
                                return parseDayjsDate(undefined)
                            }
                            return typeof dependency_value === 'object'
                                ? parseDayjsDate(dependency_value)
                                : dependency_value.toString()
                        }
                        if (
                            typeof getDependencyValue() === 'string' &&
                            typeof parsed_value === 'string'
                        ) {
                            has_fulfilled_condition = value
                                ? validation_rules.dateDiffRule(
                                      parsed_value,
                                      getDependencyValue(),
                                      false
                                  )
                                : true
                        }
                    }
                    break

                default:
                    if (condition === '>=') {
                        if (
                            typeof dependency_value === 'number' &&
                            typeof value === 'number'
                        ) {
                            has_fulfilled_condition = value >= dependency_value
                        }
                    } else if (condition === '<=') {
                        if (
                            typeof dependency_value === 'number' &&
                            typeof value === 'number'
                        ) {
                            has_fulfilled_condition = value <= dependency_value
                        }
                    } else if (condition === '!=') {
                        has_fulfilled_condition = value !== dependency_value
                    } else if (condition === '=') {
                        has_fulfilled_condition = value === dependency_value
                    }
                    break
            }
            if (!value || has_fulfilled_condition) {
                return Promise.resolve()
            }
            return Promise.reject(new Error(error_msg))
        },
    }
}

export const handleCampaignFieldValidations = (
    validationRule?: ObjectWithAnyKey[],
    field_type?: string,
    sub_type?: string
) => {
    if (!validationRule) return undefined
    const required_rule = validationRule.filter(
        (rule: ObjectWithAnyKey) => rule.required
    )[0]
    if (field_type === 'checkbox' && !isEmptyObject(required_rule)) {
        const checkbox_validation = {
            transform: (value: unknown) => value || undefined,
            type: 'boolean',
        }
        Object.assign(required_rule, { ...checkbox_validation })
    }

    const mapped_validation_keys = [
        ...Object.keys(validation_rules),
        'dependence',
    ]

    const additional_rules: ObjectWithAnyKey[] = []
    validationRule.forEach((rule: ObjectWithAnyKey) => {
        const filtered_validation_rules = Object.keys(rule).filter(
            (name: string) => !['message', 'required'].includes(name)
        )

        filtered_validation_rules.forEach((rule_name: string) => {
            if (mapped_validation_keys.includes(rule_name)) {
                const rule_value = rule[rule_name]

                const validateFunction: ValidationFunction =
                    validation_rules[rule_name as keyof typeof validation_rules]
                const validation_rule =
                    rule_name === 'dependence'
                        ? // Need to use any and no-explicit-any doesn't apply here as declaring method
                          // unknown type doesn't work in this block for antd form hook decorators - e.g -> { getFieldValue } = useForm()
                          // eslint-disable-next-line  @typescript-eslint/no-explicit-any
                          ({ getFieldValue }: any) => {
                              const { name, condition, type } = rule?.dependence
                              return validationDependencyHandler(
                                  type,
                                  condition,
                                  getFieldValue(name),
                                  rule.message,
                                  name
                              )
                          }
                        : {
                              validator: (_: unknown, value: string) => {
                                  if (
                                      sub_type === 'phone' &&
                                      rule_name === 'regex'
                                  ) {
                                      const phone_rules = ['mobile, landline']
                                      return (value &&
                                          validation_rules.phoneRule(
                                              value,
                                              phone_rules
                                          )) ||
                                          !value
                                          ? Promise.resolve()
                                          : Promise.reject(
                                                new Error(rule.message)
                                            )
                                  }
                                  return (value &&
                                      validateFunction(value, rule_value)) ||
                                      !value
                                      ? Promise.resolve()
                                      : Promise.reject(new Error(rule.message))
                              },
                          }
                additional_rules.push(validation_rule)
            }
        })
    })

    return (
        isEmptyArray(additional_rules)
            ? [{ ...required_rule }]
            : [{ ...required_rule }, ...additional_rules]
    ).filter((added_rule: ObjectWithAnyKey) =>
        typeof added_rule === 'function'
            ? true
            : Object.keys(added_rule).length !== 0
    )
}

export const appendTncDocumentsLink = (
    name: string,
    string: string,
    campaignProduct: ObjectWithAnyKey
) => {
    const { tncUrl, tncURL, pdsUrl, pdsURL } = campaignProduct
    let replace_string: string | null = string
    switch (name) {
        case 'tnc':
            if (tncUrl || tncURL) {
                const tnc_regex = new RegExp('{tncUrl\\|tncURL}', 'i')
                replace_string = string.replace(tnc_regex, tncUrl || tncURL)
            } else {
                replace_string = null
            }
            break

        case 'pds':
            if (pdsUrl || pdsURL) {
                const pds_regex = new RegExp('{pdsUrl\\|pdsURL}', 'i')
                replace_string = string.replace(pds_regex, pdsUrl || pdsURL)
            } else {
                replace_string = null
            }
            break

        default:
            break
    }

    return replace_string ? parseStringToHTML(replace_string) : null
}

export const removeCampaignIdsFromFieldNames = (field_name: string) => {
    const prefix_str = field_name
        .substring(0, field_name.indexOf('_'))
        .replace('_', '')
    const type_str = field_name.substring(field_name.indexOf('_') + 1)
    return type_str.replace(prefix_str.toUpperCase(), '')
}
