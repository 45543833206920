import { IContextContent } from 'Definitions'
import { fetchAPI } from 'Utils'

export const getContext = async (token: string, phone: string) => {
    if (!token) return []
    const context = await fetchAPI(
        `/context?phone=${phone}`,
        null,
        token,
        'GET'
    )
    return context
}

export const updateContext = async (
    token: string,
    transcriptId: string,
    content: IContextContent,
    updatedBy: string,
    postUpdateCalls?: string[]
) => {
    if (!token) return []
    const body = {
        transcriptId,
        content,
        updatedBy,
    }
    if (postUpdateCalls) {
        Object.assign(body, { postUpdateCalls })
    }
    const updated_context = await fetchAPI(`/context`, body, token, 'POST')
    return updated_context
}
