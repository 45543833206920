import * as React from 'react'
import { IAuthState } from 'Definitions'
import { useAuth } from 'Services'

export const AuthContext = React.createContext<IAuthState>({
    user: null,
    user_info: null,
    token: null,
    theme: null,
    getUser: () => {},
    toggleTheme: () => {},
    signIn: () => {},
    signOut: () => {},
    is_ui_blocked: false,
    setBlockUI: () => {},
    block_ui_message: '',
    setBlockUIMessage: () => {},
})

export const AuthProvider = ({ children }: IAuthProps) => {
    const auth = useAuth()

    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}
interface IAuthProps {
    children: React.ReactNode
}
