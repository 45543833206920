import { ObjectWithAnyKey } from 'Definitions'

export const fetchAPI = async (
    request_type: string,
    payload?: ObjectWithAnyKey | null,
    token?: string,
    method?: string
    // Need to cater for the returned promise types for all the API calls,
    // since this is a common API util helper, we use <any> to cater for all possible types
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
): Promise<any> => {
    const baseUrl = process.env.REACT_APP_AMPLIFY_API_URI
    const requestUrl = baseUrl + request_type

    const body = method === 'POST' ? JSON.stringify(payload) : null

    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    if (token) headers.append('Authorization', `Bearer ${token}`)
    const options = {
        method,
        body,
        headers,
    }

    try {
        const response = await fetch(requestUrl, options)
        const { ok, status }: Response = response

        // check for methods below that do not contain any body in response
        const data =
            method === 'DELETE' ||
            (request_type === '/context' && method === 'GET') ||
            (request_type === '/context' && method === 'POST')
                ? response
                : await response.json()

        return ok
            ? data
            : {
                  error: 'The request returned an error in the response',
                  // 401: Unauthorized, 403: Forbidden, 500: Server error, 502: Unknown request error
                  error_body: ([401, 403, 500, 502].includes(status)
                      ? data
                      : await data.json()) || [
                      {
                          key: 'unknownError',
                          description: 'Unknown error occurred',
                      },
                  ],
              }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        // Catch 504 errors as server / gateway timeout errors return text instead of json and need to be caught before parsing response data
        // TODO: Store server / gateway errors into Sentry or bugsnag
        return {
            error: 'gatewayError',
            error_body: {
                key: 'gatewayError',
                description: 'Server request timed out. Please try again.',
            },
        }
    }
}
