import * as React from 'react'
import { ICampaignState } from 'Definitions'
import { useCampaign } from 'Services'

export const CampaignContext = React.createContext<ICampaignState>({
    branch_places: [],
    campaignId: '',
    campaignProduct: {},
    customer_context: {},
    setBranchPlaces: () => [],
    setCampaignId: () => '',
    setCampaignProduct: () => {},
    setCustomerContext: () => {},
})

export const CampaignProvider = ({ children }: ICampaignProps) => {
    const campaign_data = useCampaign()

    return (
        <CampaignContext.Provider value={campaign_data}>
            {children}
        </CampaignContext.Provider>
    )
}

interface ICampaignProps {
    children: React.ReactNode
}
