import parse from 'html-react-parser'

export const toCamelCase = (string: string) =>
    string
        .replace(/(?:^.|[A-Z]|\b.)/g, (letter: string, index: number) =>
            index === 0 ? letter.toLowerCase() : letter.toUpperCase()
        )
        .replace(/\s+/g, '')

export const toSnakeCase = (string: string) =>
    string.toLowerCase().split(' ').join('_')

export const isAnchorTag = (string?: string) =>
    typeof string === 'string' ? /^<a.*>.*<\/a>/i.test(string) : false

export const parseStringToHTML = (string: string) => parse(string)
