import { parseDayjsDate, VALIDATION_RULES as rules } from 'Utils'

export const CUSTOMER_FIELD_CONFIG = {
    personalInfo: {
        countryCode: { title: 'Country', is_immutable: true, type: 'hidden' },
        birthPlace: {
            title: 'Place of Birth',
            is_immutable: true,
            type: 'hidden',
        },
        id: {
            title: 'NRIC',
            is_immutable: true,
        },
        gender: { title: 'Gender', is_immutable: true, type: 'hidden' },
        birthDate: { type: 'date', title: 'Date of Birth', is_immutable: true },
    },
    fullName: {
        title: 'Name as per IC',
        is_immutable: true,
    },
    phone: { title: 'Mobile', is_immutable: true },
    email: { title: 'Email', is_immutable: true },
    companyPosition: {
        title: 'Job Title',
        placeholder: 'Select job title',
        rules: [
            { required: true, message: 'Job Title is required' },
            {
                validator: (_: unknown, value: string) =>
                    (value && rules.nameRule(value)) || !value
                        ? Promise.resolve()
                        : Promise.reject(
                              new Error(
                                  "Job Title should only contain letters, spaces and special characters @ - ' , . and /"
                              )
                          ),
            },
            {
                validator: (_: unknown, value: string) =>
                    (value && rules.maxLength(value, 50)) || !value
                        ? Promise.resolve()
                        : Promise.reject(
                              new Error(
                                  'Maximum Job Title length is 50 characters'
                              )
                          ),
            },
        ],
    },
    grossAnnualIncome: {
        type: 'hidden',
    },
    monthlyNettIncome: {
        type: 'hidden',
    },
    monthlyGrossIncome: {
        type: 'number',
        has_currency: true,
        title: 'Monthly Gross Income',
        placeholder: 'Enter monthly gross income',
        rules: [
            { required: true, message: 'Monthly Gross Income is required' },
            {
                validator: (_: unknown, value: number) =>
                    (value && rules.onlyTwoDecimals(value)) || !value
                        ? Promise.resolve()
                        : Promise.reject(
                              new Error('Only up to two decimal places allowed')
                          ),
            },
            {
                validator: (_: unknown, value: number) =>
                    (value && rules.maxDigit(Math.trunc(value), 7)) || !value
                        ? Promise.resolve()
                        : Promise.reject(
                              new Error('Only up to seven digits allowed')
                          ),
            },
        ],
    },
    incomeType: {
        type: 'dropdown',
        title: 'Employment Type',
        placeholder: 'Select employment type',
        rules: [{ required: true, message: 'Employment type is required' }],
        options: [
            { text: 'Salaried', value: 'Salary' },
            { text: 'Self-Employed', value: 'Self Employed' },
            { text: 'Commission Earner', value: 'Commission Earner' },
        ],
    },
    companyJoinDate: {
        type: 'date',
        title: 'Employment Start Date',
        placeholder: 'Select employment start date',
        dependencies: ['birthDate'],
        rules: [
            { required: true, message: 'Employment start date is required' },
            ({ getFieldValue }: unknown) => ({
                validator(_: unknown, value: string) {
                    const date_diff = value
                        ? rules.dateDiffRule(value, getFieldValue('birthDate'))
                        : true
                    if (!value || date_diff) {
                        return Promise.resolve()
                    }
                    return Promise.reject(
                        new Error(
                            'Employment Start Date should be after Date of Birth'
                        )
                    )
                },
            }),
            {
                validator(_: unknown, value: string) {
                    const date_diff = value
                        ? rules.dateDiffRule(
                              value,
                              parseDayjsDate(undefined),
                              false
                          )
                        : true
                    if (!value || date_diff) {
                        return Promise.resolve()
                    }
                    return Promise.reject(
                        new Error(
                            'Employment Start Date should not be future dated'
                        )
                    )
                },
            },
        ],
    },
    highestAcademic: {
        type: 'dropdown',
        title: 'Education Level',
        placeholder: 'Select level of education',
        rules: [{ required: true, message: 'Level of education is required' }],
        options: [
            { text: 'Primary School', value: 'Primary' },
            { text: 'Secondary School', value: 'Secondary' },
            { text: 'Diploma', value: 'Diploma' },
            { text: 'Bachelor’s Degree', value: 'Degree' },
            { text: 'Master’s Degree', value: 'Master' },
            { text: 'Doctoral Degree', value: 'PhD' },
        ],
    },
    noOfDependents: {
        type: 'number',
        title: 'Number of Dependents',
        placeholder: 'Select number of dependents',
        rules: [
            { required: true, message: 'Number of Dependents is required' },
            {
                validator: (_: unknown, value: number) =>
                    (value && rules.minValue(value, 0)) || !value
                        ? Promise.resolve()
                        : Promise.reject(
                              new Error('Minimum Number of Dependents is 0')
                          ),
            },
            {
                validator: (_: unknown, value: number) =>
                    (value && rules.maxValue(value, 20)) || !value
                        ? Promise.resolve()
                        : Promise.reject(
                              new Error('Maximum Number of Dependents is 20')
                          ),
            },
        ],
    },
    residenceDate: {
        type: 'date',
        title: 'Residence Move In Date',
        placeholder: 'Select residence move in date',
        dependencies: ['birthDate'],
        rules: [
            { required: true, message: 'Residence move in date is required' },
            ({ getFieldValue }: unknown) => ({
                validator(_: unknown, value: string) {
                    const date_diff = value
                        ? rules.dateDiffRule(value, getFieldValue('birthDate'))
                        : true
                    if (!value || date_diff) {
                        return Promise.resolve()
                    }
                    return Promise.reject(
                        new Error(
                            'Residence Move In Date should be after Date of Birth'
                        )
                    )
                },
            }),
            {
                validator(_: unknown, value: string) {
                    const date_diff = value
                        ? rules.dateDiffRule(
                              value,
                              parseDayjsDate(undefined),
                              false
                          )
                        : true
                    if (!value || date_diff) {
                        return Promise.resolve()
                    }
                    return Promise.reject(
                        new Error(
                            'Residence Move In Date should not be future dated'
                        )
                    )
                },
            },
        ],
    },
}
