import { getDateDiff, isValidPhoneNumber, setPhoneCountryCode } from 'Utils'

export const VALIDATION_RULES = {
    regex: (value: number | string, regex: string) => {
        const regExp = new RegExp(regex, 'g')
        return regExp.test(value.toString())
    },
    minDigit: (value: number, length: number) =>
        value.toString().length >= length,
    maxDigit: (value: number, length: number) =>
        value.toString().length <= length,
    minValue: (value: number, length: number) => value >= length,
    maxValue: (value: number, length: number) => value <= length,
    minLength: (value: string | number, length: number) =>
        value.toString().length >= length,
    maxLength: (value: string | number, length: number) =>
        value.toString().length <= length,
    isDividableBy: (value: number, mod: number) => value % mod === 0,
    nameRule: (value: string) => /^[A-Za-z-@'.,/ ]{0,100}$/g.test(value),
    phoneRule: (value: string, rules = ['mobile']) => {
        if (!isValidPhoneNumber(value)) return false
        const formatted_number = setPhoneCountryCode(value)
        if (rules.includes('mobile') && !rules.includes('landline')) {
            return /^601[0-9-]{7,12}$/g.test(formatted_number)
        }
        if (rules.includes('landline') && !rules.includes('mobile')) {
            return /^60(?!1|2)[0-9]{8,13}$/g.test(formatted_number)
                ? /^60[0-9-]{7,13}$/g.test(formatted_number)
                : false
        }
        return /^60[0-9-]{7,13}$/g.test(formatted_number)
    },
    addressRule: (value: string) =>
        /^[A-Za-z0-9-,.\\/\\\\&()#@ ]{0,255}$/g.test(value),
    postCodeRule: (value: string) => /^[0-9]{5,5}$/g.test(value),
    unitNumRule: (value: string) => /^[A-Za-z0-9-.&,# ]{0,30}$/.test(value),
    dateDiffRule: (
        first_date: string,
        second_date: string,
        is_future_date = true
    ) =>
        is_future_date
            ? getDateDiff(first_date, second_date) > 0
            : getDateDiff(first_date, second_date) <= 0,
    noSpecialCharsAndSpaces: (value: string) =>
        /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/g.test(value),
    onlyTwoDecimals: (value: number) =>
        /^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/g.test(value.toString()),
}
