import * as React from 'react'
import { IBranchListResponse, ObjectWithAnyKey } from 'Definitions'
import { fetchAPI } from 'Utils'

export const getCampaignFields = async (token: string, campaignId: string) => {
    if (!token) return {}
    const request_string = `/campaigns/details?campaign_id=${campaignId}`
    const campaign_fields = await fetchAPI(request_string, null, token, 'GET')
    return campaign_fields
}

export const getSearchSuggestions = async (
    token: string,
    type: string,
    input_value: string,
    campaignId?: string
) => {
    if (!token) return {}
    let request_string = `/codematcher?type=${type}&value=${input_value}`
    if (campaignId) {
        request_string += `&campaign_id=${campaignId}`
    }

    const search_results = await fetchAPI(request_string, null, token, 'GET')
    return search_results
}

export const calculateNettIncome = async (
    token: string,
    monthlyGrossIncome: number
) => {
    if (!token) return {}
    const request_string = `/calculator/netincome?monthly_gross_income=${monthlyGrossIncome}`
    const monthly_nett_income = await fetchAPI(
        request_string,
        null,
        token,
        'GET'
    )
    return monthly_nett_income
}

export const useCampaign = () => {
    const { useState } = React
    const [campaignId, setCampaignId] = useState<string>('')
    const [campaignProduct, setCampaignProduct] = useState<ObjectWithAnyKey>({})
    const [branch_places, setBranchPlaces] = useState<IBranchListResponse[]>([])
    const [customer_context, setCustomerContext] = useState<ObjectWithAnyKey>(
        {}
    )

    return {
        branch_places,
        setBranchPlaces,
        campaignId,
        campaignProduct,
        setCampaignId,
        customer_context,
        setCampaignProduct,
        setCustomerContext,
    }
}
