import { CallBackType } from 'Definitions'
import { fetchAPI } from 'Utils'

export const listUsers = async (token: string, groupName: string) => {
    if (!token) return []
    const users_list = await fetchAPI(
        `/users/groupusers?group_name=${groupName}`,
        null,
        token,
        'GET'
    )
    return users_list
}

export const listGroups = async (token: string) => {
    if (!token) return []
    const groups_list = await fetchAPI('/users/groups', null, token, 'GET')
    return groups_list
}

export const deleteUser = async (
    token: string,
    email: string,
    cb?: CallBackType
) => {
    const response = await fetchAPI(
        `/users/delete?email=${email}`,
        null,
        token,
        'DELETE'
    )
    if (typeof cb?.callBack === 'function') cb.callBack()
    return response
}

export const createUser = async (
    token: string,
    email: string,
    groupName: string,
    cb?: CallBackType
) => {
    const body = { email, groupName }
    const response = await fetchAPI('/users/create', body, token, 'POST')
    if (typeof cb?.callBack === 'function') cb.callBack()
    return response
}
