export const CAMPAIGN_FORM_CONFIG = {
    getLayout: (title: string) =>
        title === 'Others' ? 'vertical' : 'horizontal',
    getLabelCol: (title: string) => {
        return {
            xs: { span: 24 },
            sm: {
                span: title === 'Others' ? 12 : 8,
                offset: title === 'Others' ? 6 : undefined,
            },
            md: {
                span: title === 'Others' ? 12 : 8,
                offset: title === 'Others' ? 6 : undefined,
            },
            lg: {
                span: title === 'Others' ? 12 : 8,
                offset: title === 'Others' ? 6 : undefined,
            },
        }
    },
    getWrapperCol: (title: string) => {
        return {
            xs: {
                span: 24,
            },
            sm: {
                span: 12,
                offset: title === 'Others' ? 6 : undefined,
            },
            md: {
                span: 12,
                offset: title === 'Others' ? 6 : undefined,
            },
            lg: {
                span: 12,
                offset: title === 'Others' ? 6 : undefined,
            },
        }
    },
}

export const CAMPAIGN_ISSUER_ID = {
    uobk007: 'MY_UOBK_branches',
    bsn008: 'MY_BSN_branches',
    aeon004: 'MY_AEON_branches',
    aeon006: 'MY_AEON_branches',
    pbbk005: 'MY_PBBK_CRCD_branches',
    rhb005: 'MY_RHB_branches',
    rhbk007: 'MY_RHB_CRCD_branches',
}

export const ADDRESS_DEPENDENCY_FIELDS_MAP = {
    homeAddress: [
        'residenceUnitNo',
        'residenceAddress',
        'residencePostalCode',
        'residenceCity',
        'residenceState',
    ],
    officeAddress: [
        'employmentAddress',
        'employmentUnitNo',
        'employmentCity',
        'employmentState',
        'employmentPostalCode',
    ],
}

// handle special cases for dropdown fields with boolean string values
export const BINARY_DROPDOWNS = [
    'bumiputera',
    'director',
    'relatedToStaff',
    'isNonBankCommitment',
    'fatcaStatus',
    'signatureTransactionAlert',
]

export const ADDRESS_FIELDS_CONTEXT_MAP = {
    homeAddress: {
        address: 'residenceAddress',
        city: 'residenceCity',
        postcode: 'residencePostalCode',
        state: 'residenceState',
        unit: 'residenceUnitNo',
    },
    officeAddress: {
        address: 'employmentAddress',
        city: 'employmentCity',
        postcode: 'employmentPostalCode',
        state: 'employmentState',
        unit: 'employmentUnitNo',
    },
}

export const FORM_ERROR_MESSAGES = {
    homeAddress: 'Residence Address Fields',
    officeAddress: 'Employment Address Fields',
    residenceAddress: 'Residence Address',
    residenceUnitNo: 'Residence Unit No',
    residencePostalCode: 'Residence Postal Code',
    residenceCity: 'Residence City',
    residenceState: 'Residence State',
    employmentAddress: 'Employment Address',
    employmentUnitNo: 'Employment Unit No',
    employmentCity: 'Employment City',
    employmentState: 'Employment State',
    employmentPostalCode: 'Employment Postal Code',
    homePhone: 'Home Phone',
    officePhone: 'Office Phone',
    rhb005_emergencyContactPhoneRHB005: 'Emergency Contact Phone',
    relatedToStaffPhone: "Relative's contact number",
}
