import { parseDayjsDate } from 'Utils'

export const USER_TABLE_CONFIG = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (name: string) => (!name ? 'Not Set' : name),
    },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    {
        title: 'Status',
        dataIndex: 'enabled',
        key: 'enabled',
        render: (status: boolean) => (status ? 'Enabled' : 'Disabled'),
    },
    {
        title: 'Group Name',
        dataIndex: 'groupName',
        key: 'groupName',
    },
    {
        title: 'Created At',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (date: string) => parseDayjsDate(date, 'llll'),
    },
]

export const BLOCK_UI_TIMER = 10
