import * as React from 'react'
import { Layout } from 'antd'
import { useThemeSwitcher } from 'react-css-theme-switcher'

const FooterComponent: React.FC = () => {
    const { Footer } = Layout
    const { currentTheme } = useThemeSwitcher()
    const year = new Date().getFullYear()
    return (
        <Footer
            style={{
                textAlign: 'center',
                backgroundColor:
                    currentTheme === 'dark' ? '#1d1d1d' : '#f0f2f5',
            }}
        >
            {`© ${year} Jirnexu Sdn. Bhd. All rights reserved.`}
        </Footer>
    )
}

export default FooterComponent
