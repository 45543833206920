import * as React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ROUTES_CONFIG as routes } from 'Config'
import PrivateRoute from './private-route'

const { Suspense, lazy } = React

const NotFound = lazy(() => import('Components/Pages/NotFound'))

const Routes: React.FC = () => (
    <Suspense fallback={<div>Loading...</div>}>
        <Switch>
            {routes.map((route) => {
                const {
                    component,
                    is_authenticated,
                    is_exact,
                    key,
                    path,
                    roles,
                } = route
                if (is_authenticated)
                    return (
                        <PrivateRoute
                            component={component}
                            exact={is_exact}
                            path={path}
                            roles={roles}
                            key={key}
                        />
                    )
                return (
                    <Route
                        component={component}
                        exact={is_exact}
                        path={path}
                        key={key}
                    />
                )
            })}
            <Route>
                <NotFound />
            </Route>
        </Switch>
    </Suspense>
)

export default Routes
