import merge from 'deepmerge'
import {
    TCustomerField,
    ObjectWithAnyKey,
    TPersonalInfoValues,
    TPhoneValue,
    IBranchListResponse,
} from 'Definitions'
import {
    CUSTOMER_FIELD_CONFIG as customer_field_config,
    BINARY_DROPDOWNS as binary_dropdowns,
} from 'Config'
import { parseDayjsDate, filterObjectByPredicate } from 'Utils'
import dayjs from 'dayjs'

export const getFieldsData = (context_data: ObjectWithAnyKey) => {
    if (!context_data) return null
    const mapped_keys = Object.keys(customer_field_config)
    const filterByMappedFields = ([field]: string[]) =>
        mapped_keys.includes(field)

    return filterObjectByPredicate(context_data, filterByMappedFields)
}

export const getCustomerFields = (context_data: ObjectWithAnyKey) => {
    const mapped_fields = Object.fromEntries(
        Object.entries(customer_field_config)
    )
    const fields_data = getFieldsData(context_data)
    const parsed_fields_data = {}
    Object.keys(customer_field_config).forEach((field: string) => {
        if (fields_data && Object.keys(fields_data).includes(field)) {
            const { value } = fields_data[field] as TCustomerField
            if (field === 'personalInfo') {
                const { birthDate, birthPlace, countryCode, gender, id } =
                    value as TPersonalInfoValues

                const personal_info_values = {
                    birthDate: { value: birthDate },
                    birthPlace: { value: birthPlace },
                    countryCode: { value: countryCode },
                    gender: { value: gender },
                    id: { value: id },
                }
                Object.assign(parsed_fields_data, {
                    [field]: personal_info_values,
                })
            } else if (field === 'phone') {
                const { msisdn } = value as TPhoneValue
                Object.assign(parsed_fields_data, {
                    [field]: { value: msisdn },
                })
            } else {
                Object.assign(parsed_fields_data, {
                    [field]: { value },
                })
            }
        } else {
            // Handle users without any context
            Object.assign(
                parsed_fields_data,
                field === 'personalInfo'
                    ? {
                          [field]: {
                              birthDate: { value: null },
                              birthPlace: { value: null },
                              countryCode: { value: null },
                              gender: { value: null },
                              id: { value: null },
                          },
                      }
                    : {
                          [field]: { value: null },
                      }
            )
        }
    })

    const customer_fields = merge(mapped_fields, parsed_fields_data)
    return customer_fields
}

export const getImmutableFieldKeys = (
    customer_fields_config: ObjectWithAnyKey
) =>
    Object.keys(customer_fields_config).filter((field: string) => {
        if (field === 'personalInfo') {
            const nested_fields = customer_fields_config[field]
            return Object.keys(nested_fields).some(
                (nested_field: string) =>
                    nested_fields[nested_field].is_immutable
            )
        }
        return customer_fields_config[field].is_immutable
    })

export const getMutableFieldKeys = (customer_fields: ObjectWithAnyKey) =>
    Object.keys(customer_fields).filter((field: string) => {
        const immutable_field_keys = getImmutableFieldKeys(
            customer_field_config
        )
        return !immutable_field_keys.includes(field)
    })

export const handleCustomerFieldTypes = (
    field: string,
    value: string | number | boolean | ObjectWithAnyKey | TPhoneValue,
    type?: string,
    should_parse = true,
    all_fields?: ObjectWithAnyKey,
    subType?: string
) => {
    if (type === 'branch' && typeof value === 'object') {
        const { placeId } = value as IBranchListResponse
        if (placeId) {
            return placeId
        }
    }
    if (field === 'monthlyGrossIncome' && all_fields) {
        const { grossAnnualIncome } = all_fields
        if (grossAnnualIncome) {
            return (grossAnnualIncome / 12).toFixed(2)
        }
    }
    if ((field === 'phone' || subType === 'phone') && value) {
        const { msisdn } = value as TPhoneValue
        return value === '-' ? undefined : msisdn || value
    }
    if (
        type === 'dropdown' &&
        typeof value === 'boolean' &&
        binary_dropdowns.includes(field)
    ) {
        return `${value}`
    }
    if (type === 'date' && should_parse) {
        // To handle weird cases when date type is returned as true or false ¯\_(ツ)_/¯
        if (typeof value === 'boolean') return null
        return typeof value === 'string' ? dayjs(value) : value
    }
    return value
}

export const getAllFields = (customer_fields: ObjectWithAnyKey) => {
    const all_fields = {}
    Object.keys(customer_fields).forEach((field: string) => {
        if (field === 'personalInfo') {
            const nested_fields = customer_fields[field]
            Object.keys(nested_fields).forEach((nested_field) => {
                Object.assign(all_fields, {
                    [nested_field]: handleCustomerFieldTypes(
                        nested_field,
                        nested_fields[nested_field].value,
                        nested_fields[nested_field].type,
                        true,
                        all_fields,
                        nested_fields[nested_field].subType
                    ),
                })
            })
        } else {
            Object.assign(all_fields, {
                [field]: handleCustomerFieldTypes(
                    field,
                    customer_fields[field].value,
                    customer_fields[field].type,
                    true,
                    all_fields,
                    customer_fields[field].subType
                ),
            })
        }
    })
    return all_fields
}

export const getMutableFields = (
    customer_fields: ObjectWithAnyKey,
    should_parse = true
) => {
    const mutable_fields = {}
    getMutableFieldKeys(customer_fields).forEach((field: string) =>
        Object.assign(mutable_fields, {
            [field]: handleCustomerFieldTypes(
                field,
                customer_fields[field].value,
                customer_fields[field].type,
                should_parse,
                mutable_fields
            ),
        })
    )
    return mutable_fields
}

export const parseCustomerFields = (customer_fields: ObjectWithAnyKey) => {
    const { residenceDate, companyJoinDate, monthlyGrossIncome } =
        customer_fields
    const parsed_customer_fields = {
        ...customer_fields,
        ...{
            grossAnnualIncome: monthlyGrossIncome * 12,
            residenceDate: parseDayjsDate(residenceDate),
            companyJoinDate: parseDayjsDate(companyJoinDate),
        },
    }
    return parsed_customer_fields
}
