import { ObjectWithAnyKey } from 'Definitions'
import { deepMergeObjects } from 'Utils'

export const syncContextData = (
    context_data: ObjectWithAnyKey,
    form_values: ObjectWithAnyKey
) => {
    const updated_context_values = {}
    const updated_values_obj = {}
    Object.keys(form_values).forEach((field: string) => {
        const context_field_value = {
            [field]: {
                value: form_values[field],
            },
        }
        Object.assign(updated_values_obj, context_field_value)
    })
    Object.assign(updated_context_values, {
        context: updated_values_obj,
    })
    const merged_context_data = deepMergeObjects(
        context_data,
        updated_context_values
    )
    return merged_context_data
}
