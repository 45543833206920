import { ObjectWithAnyKey } from 'Definitions'

export const getUserRoles = (cognitoUserGroup?: string[]) => {
    const roles = []
    if (Array.isArray(cognitoUserGroup)) {
        const is_admin = cognitoUserGroup.filter((role) =>
            /group-admin/.test(role)
        )[0]
        if (is_admin) roles.push('admin')

        const is_rptm = cognitoUserGroup.filter((role) =>
            /group-rptm/.test(role)
        )[0]
        if (is_rptm) roles.push('rptm')
    }
    return roles
}

export const getNameFromEmail = (email: string) =>
    email.substring(0, email.lastIndexOf('@'))

export const getDomainFromEmail = (email: string) =>
    email.substring(email.lastIndexOf('@') + 1)

export const getSupportedGroups = (groups: string[]) =>
    groups.filter(
        (group: string) => /group-admin/.test(group) || /group-rptm/.test(group)
    )

export const getUserGroups = (user_info?: ObjectWithAnyKey | null) =>
    user_info ? user_info['cognito:groups'] : []

export const getUserGroupName = (user_info: ObjectWithAnyKey) => {
    const cognito_user_groups = user_info['cognito:groups']
    const roles = getUserRoles(cognito_user_groups)
    if (roles.includes('admin')) {
        return cognito_user_groups.filter((role: string) =>
            /group-admin/.test(role)
        )
    }
    return cognito_user_groups.filter((role: string) => /group-rptm/.test(role))
}

export const getUserProfile = (user_info?: ObjectWithAnyKey | null) => {
    if (!user_info) return {}
    const { email, name, email_verified, picture, auth_time } = user_info
    return { email, name, email_verified, picture, auth_time }
}
