import * as React from 'react'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { AuthContext } from 'Store'
import { useThemeSwitcher } from 'react-css-theme-switcher'
import { ROUTES_CONFIG as pages } from 'Config'
import { Row, Col, Button, Breadcrumb, Space, Layout } from 'antd'
import { RouteConfigTypes } from 'Definitions'

const AppContents = ({
    children,
    location,
}: TAppContents & RouteComponentProps) => {
    const { useContext } = React
    const { Content } = Layout
    const authCtx = useContext(AuthContext)
    const { user, signOut } = authCtx
    const { currentTheme } = useThemeSwitcher()
    return (
        <Content style={{ padding: '0 50px', marginTop: 64 }}>
            {user ? (
                <Row>
                    <Col span={18}>
                        <Breadcrumb style={{ margin: '16px 0' }}>
                            <Breadcrumb.Item>
                                <Link to="/">Home</Link>
                            </Breadcrumb.Item>
                            {pages
                                .filter((page) => {
                                    const { path } = page as RouteConfigTypes
                                    const { pathname } = location
                                    return path === pathname
                                })
                                .map((page) => {
                                    const { path, title } = page
                                    const link_pathname = Array.isArray(path)
                                        ? path[0]
                                        : path

                                    return (
                                        <Breadcrumb.Item key={link_pathname}>
                                            <Link to={link_pathname}>
                                                {title}
                                            </Link>
                                        </Breadcrumb.Item>
                                    )
                                })}
                        </Breadcrumb>
                    </Col>
                    <Col span={6}>
                        <Space
                            align="center"
                            style={{
                                height: '100%',
                                width: '100%',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Button
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    marginLeft: 'auto',
                                }}
                                onClick={signOut}
                            >
                                Sign Out
                            </Button>
                        </Space>
                    </Col>
                </Row>
            ) : (
                <div style={{ margin: '56px 0' }} />
            )}
            <div
                style={{
                    padding: 24,
                    minHeight: 'calc(100vh - 190px)',
                    backgroundColor:
                        currentTheme === 'light' ? '#fff' : '#303030',
                }}
            >
                {children}
            </div>
        </Content>
    )
}

type TAppContents = {
    children: React.ReactNode
}

export default withRouter(AppContents)
