import * as React from 'react'
import { Spin } from 'antd'
import { AuthContext } from 'Store'
import { useThemeSwitcher } from 'react-css-theme-switcher'
import { BLOCK_UI_TIMER } from 'Config'

const INITIAL_TIMER = BLOCK_UI_TIMER
const TARGET_TIMER = 0

const UIBlockerComponent: React.FC = () => {
    const { useContext, useEffect, useState, useRef } = React
    const authCtx = useContext(AuthContext)
    const { is_ui_blocked, setBlockUI, block_ui_message, setBlockUIMessage } =
        authCtx
    const { currentTheme } = useThemeSwitcher()

    const [timer, setTimer] = useState(INITIAL_TIMER)
    const interval = useRef<NodeJS.Timeout | null>(null)

    useEffect(() => {
        if (!is_ui_blocked) return
        const handleTimer = () => {
            interval.current = setInterval(() => {
                setTimer((count) => count - 1)
            }, 1000)
        }

        if (timer <= TARGET_TIMER && interval.current) {
            clearInterval(interval.current)
            setTimer(INITIAL_TIMER)
            setBlockUI(false)
            setBlockUIMessage('')
        }

        if (timer === INITIAL_TIMER) handleTimer()
    }, [is_ui_blocked, setBlockUI, setBlockUIMessage, timer])

    if (!is_ui_blocked) return null
    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: currentTheme === 'dark' ? 'grey' : 'black',
                opacity: 0.7,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 99999,
            }}
        >
            <Spin
                spinning
                tip={`Please wait ${timer} seconds${
                    block_ui_message ? ` ${block_ui_message}` : '...'
                }`}
            />
        </div>
    )
}

export default UIBlockerComponent
