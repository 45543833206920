import { isLocalhost } from 'Utils'

export const AMPLIFY_CONFIG = {
    Auth: {
        identityPoolId: process.env.REACT_APP_AMPLIFY_IDENTITY_POOL_ID,
        region: process.env.REACT_APP_AMPLIFY_REGION,
        userPoolId: process.env.REACT_APP_AMPLIFY_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_AMPLIFY_APP_CLIENT_ID,
        oauth: {
            domain: process.env.REACT_APP_AMPLIFY_HOSTED_DOMAIN,
            scope: ['email', 'profile', 'openid'],
            redirectSignIn: isLocalhost
                ? 'http://localhost:3000/login'
                : process.env.REACT_APP_AMPLIFY_SIGNIN_URI,
            redirectSignOut: isLocalhost
                ? 'http://localhost:3000'
                : process.env.REACT_APP_AMPLIFY_SIGNOUT_URI,
            responseType: 'token',
        },
    },
}
