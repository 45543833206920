import * as React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { useThemeSwitcher } from 'react-css-theme-switcher'
import { Layout } from 'antd'
import { AuthProvider } from 'Store'
import { signupErrorChecker } from 'Utils'
import Routes from 'Components/Routes'
import { Header, AppContents, Footer, UIBlocker } from './Layout'

const App: React.FC = () => {
    const { currentTheme, status } = useThemeSwitcher()
    signupErrorChecker()

    return (
        <AuthProvider>
            {status !== 'loaded' ? null : (
                <Layout
                    style={{
                        backgroundColor:
                            currentTheme === 'dark' ? '#1d1d1d' : '#f0f2f5',
                    }}
                >
                    <Router>
                        <UIBlocker />
                        <Header />
                        <AppContents>
                            <Routes />
                        </AppContents>
                        <Footer />
                    </Router>
                </Layout>
            )}
        </AuthProvider>
    )
}

export default App
