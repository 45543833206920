import { VALIDATION_RULES } from 'Utils'

export const PELN_FIELD_CONFIG = {
    loanAmount: {
        type: 'number',
        has_currency: true,
        title: 'Loan Amount',
        placeholder: 'Please enter loan amount',
        rules: [
            {
                required: true,
                message: 'Loan Amount is required',
            },
            {
                validator: (_: unknown, value: number) =>
                    (value && VALIDATION_RULES.onlyTwoDecimals(value)) || !value
                        ? Promise.resolve()
                        : Promise.reject(
                              new Error('Only up to two decimal places allowed')
                          ),
            },
            {
                validator: (_: unknown, value: number) =>
                    (value &&
                        VALIDATION_RULES.maxDigit(Math.trunc(value), 7)) ||
                    !value
                        ? Promise.resolve()
                        : Promise.reject(
                              new Error('Only up to seven digits allowed')
                          ),
            },
            {
                validator: (_: unknown, value: number) =>
                    (value && VALIDATION_RULES.minValue(value, 2000)) || !value
                        ? Promise.resolve()
                        : Promise.reject(new Error('Minimum value is 2000')),
            },
            {
                validator: (_: unknown, value: number) =>
                    (value && VALIDATION_RULES.isDividableBy(value, 1000)) ||
                    !value ||
                    value < 1000
                        ? Promise.resolve()
                        : Promise.reject(
                              new Error('Only multiples of 1000 allowed')
                          ),
            },
        ],
        styles: { width: '210px', maxWidth: '100%' },
    },
    loanPurpose: {
        type: 'dropdown',
        title: 'Purpose',
        placeholder: 'Please enter loan purpose',
        rules: [
            {
                required: true,
                message: 'Loan Purpose is required',
            },
        ],
        options: [
            { text: 'Personal', value: 'Personal' },
            { text: 'Education', value: 'Education expenses' },
            { text: 'Vehicle Purchase', value: 'Vehicle purchase' },
            { text: 'Medical', value: 'Medical expenses' },
            { text: 'Wedding', value: 'Wedding' },
            { text: 'Travel', value: 'Travel' },
            { text: 'Property Downpayment', value: 'Property downpayment' },
            { text: 'Debt repayment', value: 'Debt repayment' },
            { text: 'House Renovation', value: 'Renovation' },
            { text: 'Home Furnishing', value: 'Home furnishing' },
            { text: 'Electric Goods', value: 'Electric goods' },
        ],
        styles: { width: '220px', maxWidth: '100%' },
    },
    loanTenure: {
        type: 'dropdown',
        title: 'Tenure',
        placeholder: 'Please enter loan tenure',
        rules: [
            {
                required: true,
                message: 'Loan Tenure is required',
            },
        ],
        options: [
            { text: '1 year', value: 12 },
            { text: '2 years', value: 24 },
            { text: '3 years', value: 36 },
            { text: '4 years', value: 48 },
            { text: '5 years', value: 60 },
            { text: '6 years', value: 72 },
            { text: '7 years', value: 84 },
        ],
        styles: { width: '200px', maxWidth: '100%' },
    },
}
