import { ObjectWithAnyKey } from 'Definitions'
import { union } from 'lodash'

export const getArrayMatch = (
    first_array: string[],
    second_array: string[]
) => {
    return first_array.some((item: string) => second_array.includes(item))
}

export const removeItemFromArray = <T>(array: Array<T>, value: T): Array<T> => {
    const index = array.indexOf(value)
    if (index > -1) {
        array.splice(index, 1)
    }
    return array
}

export const isEmptyArray = (array: string[] | ObjectWithAnyKey[]) =>
    array.length < 1

export const removeArrayDuplicates = (array: string[]) => {
    return union(array)
}
